body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', sans-serif;
  color: '#323E4A';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ql-container {
  height: 200px;
}

.bio p {
  padding: 0px !important;
  margin: 0px !important;
}

/* Remove default small dots */
.slick-dots li {
  margin: 0 5px;
  padding: 0;
  list-style-type: none;
  /* Ensure no default bullet points */
}

/* Styling the dots */
.slick-dots li button {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #b0c4de;
  /* Inactive dot color (light gray) */
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* Styling the active dot */
.slick-dots li.slick-active button {
  background-color: #000;
  /* Active dot color (black) */
  width: 14px;
  height: 14px;
  transition: background-color 0.3s ease, width 0.3s ease, height 0.3s ease;
}

/* Ensure there are no small dots by overriding any hidden or default buttons */
.slick-dots li button::before {
  display: none !important;
  /* This hides any default dot pseudo-elements */
}

.club-slider-test .slick-slide {
  margin: 0 10px;
}

.slick-list {
  height: 300px;
}

.MuiBox-root .css-kl1xo3{
  display: flex;
  justify-content: flex-start;
}

.gm-style iframe + div { border:none!important; }

.gm-style-cc,
.gmnoprint,
.gmnoprint a {
  display: none !important;
}

.pac-container {
  border-radius: 10px !important;
  width: 250px !important;
}

.pac-item-query {
  font-size: 16px;
}

